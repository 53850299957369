import React, { useState, useEffect } from "react";
import Navbar from "../DesktopPage/Navbar";
import Footer from "../DesktopPage/Footer";
import { staicPageDetails } from "../../Api/Api";

import { useParams } from "react-router-dom";

const Static = () => {
  let { slug } = useParams();

  const [statices, setStatices] = useState("");

  useEffect(() => {
    const loadData = () => {
      staicPageDetails(slug).then((res) => {
        const response = res;

        setStatices(response.data);
      });
    };
    loadData();
  }, [slug]);
  return (
    <>
      <Navbar />
      <div dangerouslySetInnerHTML={{ __html: statices?.content }} />
      <Footer />
    </>
  );
};

export default Static;
