// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 25%;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background: #00102c !important;
}

@media(max-width:768px) {
  .googleForm {
    width: 100%;
    max-width: 100%;

  }
}

@media(max-width:600px) {
  .googleForm {
    width: 100%;
    max-width: 100%;

  }

  .iFrame {
    width: 100%;
  }

  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 70% !important;
  }
}

@media(max-width:480px) {
  .googleForm {
    width: 100%;
    max-width: 100%;

  }

  .iFrame {
    width: 100%;
  }
}

/* @media(max-width:520px) {
  .css-1ri6ub7-MuiPaper-root-MuiCard-root {
    max-width: 420px !important
  }
} */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;;EAEZ,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE;aACW;AACb;;AAEA;EACE,UAAU;AACZ;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,WAAW;IACX,eAAe;;EAEjB;AACF;;AAEA;EACE;IACE,WAAW;IACX,eAAe;;EAEjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,WAAW;IACX,eAAe;;EAEjB;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;;;;GAIG","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  height: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.css-1160xiw-MuiPaper-root-MuiDrawer-paper {\n  width: 25%;\n}\n.css-sghohy-MuiButtonBase-root-MuiButton-root {\n  background: #00102c !important;\n}\n\n@media(max-width:768px) {\n  .googleForm {\n    width: 100%;\n    max-width: 100%;\n\n  }\n}\n\n@media(max-width:600px) {\n  .googleForm {\n    width: 100%;\n    max-width: 100%;\n\n  }\n\n  .iFrame {\n    width: 100%;\n  }\n\n  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {\n    width: 70% !important;\n  }\n}\n\n@media(max-width:480px) {\n  .googleForm {\n    width: 100%;\n    max-width: 100%;\n\n  }\n\n  .iFrame {\n    width: 100%;\n  }\n}\n\n/* @media(max-width:520px) {\n  .css-1ri6ub7-MuiPaper-root-MuiCard-root {\n    max-width: 420px !important\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
