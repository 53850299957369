import React from "react";
import Desktop from "./Pages/DesktopPage/Desktop";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Applypage from "./Pages/Apply/Applypage";
import Navbar from "./Components/DesktopPage/Navbar";
import Footer from "./Components/DesktopPage/Footer";
import SignInPage from "./Pages/SignInPage/SignInPage";
import SignUppage from "./Pages/SignUpPage/SignUppage";
import Profile from "./Pages/ProfilePage/Profile";
import ProfilesDetail from "./Pages/ProfileDetailsPage/ProfilesDetail";
import StaticsPages from "./Pages/Statics/StaticsPages";
import Task from "./Pages/Task/Task";
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Desktop />} /> */}
          <Route path="/" element={<Applypage />} />
          {/* <Route path="/" element={<Navigate to="/apply" />} /> */}
          <Route path="/signIn" element={<SignInPage />} />
          <Route path="/apply" element={<SignUppage />} />
          <Route path="/all-Ambassadors" element={<Profile />} />
          <Route path="/profile/:slug" element={<ProfilesDetail />} />
          <Route path="/:slug" element={<StaticsPages />} />
          <Route path="/task" element={<Task />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
