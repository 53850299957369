import React from "react";
import Static from "../../Components/StaicPage/Static";
import { Helmet } from "react-helmet";
const StaticsPages = () => {
  // var new_str = str.replace(/-/g, "");
  return (
    <div>
      <Static />
    </div>
  );
};

export default StaticsPages;
