import React, { useState, useEffect } from "react";
import "../../Style/HomePageCss/Card2.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SkeletonCard from "../Profile/SkeletonCard";
import { CardActionArea } from "@mui/material";
import studentConversation from "../../Utils/Images/StudentConversation.jpeg";
import { Blogs } from "../../Api/Api";
const ApplyCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const loadData = () => {
      Blogs()
        .then((res) => {
          setData(res?.data);
          if (res.status === 200) {
            setLoading(true);
          }
        })
        .catch((error) => {
          console.log("jvcbjhbv");
        });
    };
    loadData();
  }, []);
  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  console.log("data", data);
  return (
    <>
      <h1 className="whyShould">Why you should apply</h1>
      {loading === false ? (
        <div className="searchProfileCard">
          <Loader />
        </div>
      ) : (
        <div className="ApplyCardContainer">
          {data.length === 0 ? (
            <p style={{ fontSize: "25px", textAlign: "center" }}>
              Blogs Comming Soon
            </p>
          ) : (
            data?.map((value) => {
              return (
                <Card sx={{ maxWidth: 345 }} className="Cardmain">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={
                        value?.image === null
                          ? studentConversation
                          : value?.image
                      }
                      alt=""
                      className="cardMedia"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        {value?.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="blogDescripstion"
                      >
                        {value?.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })
          )}
        </div>
      )}
    </>
  );
};

export default ApplyCard;
