import React from "react";
import Navbar from "../../Components/DesktopPage/Navbar";
import ProfileDetails from "../../Components/ProfileDetails/ProfileDetails";
import { useLocation } from "react-router-dom";
import Footer from "../../Components/DesktopPage/Footer";
import SEO from "../../SEO";
const ProfilesDetail = () => {
  const { state } = useLocation();
  console.log("state", state);
  return (
    <div>
      <SEO
        title={`${state?.name} | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_url={`/profile/${state?.slug}`}
        og_type="article"
        og_title={`${state?.name} | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_description={
          state.bio ||
          "Kavishala Campus Ambassadors are a global group of on-campus Leaders who are eager to help fellow students, lead their local literary community, and develop literary awareness and poetic skills. They provide a platform for aspiring individuals to gain knowledge."
        }
        og_image={
          state?.image || "https://ambassadors.kavishala.com/images/post_og.png"
        }
      />
      <Navbar />
      <ProfileDetails state={state} />
      <Footer />
    </div>
  );
};

export default ProfilesDetail;
