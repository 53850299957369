import React from "react";
import Carousel from "react-elastic-carousel";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "../../Style/HomePageCss/CarouselCard.scss";
import Level from "../../Utils/Images/Level.png";
import Profile from "../../Utils/Images/StudentConversation.jpeg";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 250, itemsToShow: 1, itemsToScroll: 1 },
  { width: 500, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
  { width: 1250, itemsToShow: 3 },
];

const CaroselCard = () => {
  const carouselProp = {
    showStatus: true,
    useKeyboardArrows: true,
    infiniteLoop: true,
    autoPlay: true,
    stopOnHover: true,
    emulateTouch: true,
    transitionTime: 400,
    showArrows: true,
    swipeable: true,
    renderArrowPrev: (clickHandler, hasPrev, label) => {
      return (
        <span className="arrow-left" onClick={clickHandler}>
          <span className="icon-keyboard_arrow_left">
            <FontAwesomeIcon icon={faAngleLeft} color="red" />
          </span>
        </span>
      );
    },
    renderArrowNext: (clickHandler, hasNext, label) => {
      return (
        <span className="arrow-right" onClick={clickHandler}>
          <span className="icon-keyboard_arrow_right">
            <FontAwesomeIcon icon={faAngleRight} color="red" />
          </span>
        </span>
      );
    },
  };
  return (
    <div className="CarouselCardMain">
      <h1 className="template2Head">Hear from Learn Student Ambassadors</h1>
      <Carousel
        {...carouselProp}
        showThumbs={false}
        infiniteLoop={false}
        centerMode
        centerSlidePercentage={25}
        breakPoints={breakPoints}
        className="realted-crousel"
      >
        <div className="CardContainer">
          <Card sx={{ maxWidth: 345 }} className="CardMain">
            <div className="Header">
              <div className="headerImageContainer">
                <img className="headerImg" src={Level} alt="level" />
              </div>
              <div className="headerTitleContainer">
                <h1 className="Title">Beta</h1>
              </div>
            </div>

            <div className="cardMedia">
              <CardMedia
                component="img"
                height="194"
                image={Profile}
                alt="Paella dish"
                className="profileImage"
              />
            </div>
            <CardContent className="ContentMain">
              <Typography
                variant="body2"
                color="text.secondary"
                className="content"
              >
                This impressive paella is a perfect party dish and a fun meal to
                cook together with your guests. Add 1 cup of frozen peas along
                with the mussels, if you like.
              </Typography>
            </CardContent>
            <div className="footerTitleContainer">
              <h1 className="Title">
                Ram <span>India</span>
              </h1>
            </div>
          </Card>
        </div>
        <div className="CardContainer">
          <Card sx={{ maxWidth: 345 }} className="CardMain">
            <div className="Header">
              <div className="headerImageContainer">
                <img className="headerImg" src={Level} alt="level" />
              </div>
              <div className="headerTitleContainer">
                <h1 className="Title">Beta</h1>
              </div>
            </div>

            <div className="cardMedia">
              <CardMedia
                component="img"
                height="194"
                image={Profile}
                alt="Paella dish"
                className="profileImage"
              />
            </div>
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                className="content"
              >
                This impressive paella is a perfect party dish and a fun meal to
                cook together with your guests. Add 1 cup of frozen peas along
                with the mussels, if you like.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="CardContainer">
          <Card sx={{ maxWidth: 345 }} className="CardMain">
            <div className="Header">
              <div className="headerImageContainer">
                <img className="headerImg" src={Level} alt="level" />
              </div>
              <div className="headerTitleContainer">
                <h1 className="Title">Beta</h1>
              </div>
            </div>

            <div className="cardMedia">
              <CardMedia
                component="img"
                height="194"
                image={Profile}
                alt="Paella dish"
                className="profileImage"
              />
            </div>
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                className="content"
              >
                This impressive paella is a perfect party dish and a fun meal to
                cook together with your guests. Add 1 cup of frozen peas along
                with the mussels, if you like.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="CardContainer">
          <Card sx={{ maxWidth: 345 }} className="CardMain">
            <div className="Header">
              <div className="headerImageContainer">
                <img className="headerImg" src={Level} alt="level" />
              </div>
              <div className="headerTitleContainer">
                <h1 className="Title">Beta</h1>
              </div>
            </div>

            <div className="cardMedia">
              <CardMedia
                component="img"
                height="194"
                image={Profile}
                alt="Paella dish"
                className="profileImage"
              />
            </div>
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                className="content"
              >
                This impressive paella is a perfect party dish and a fun meal to
                cook together with your guests. Add 1 cup of frozen peas along
                with the mussels, if you like.
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Carousel>
    </div>
  );
};

export default CaroselCard;
