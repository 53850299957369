import React from "react";
import apply from "../../Utils/Images/IconApply.svg";
import "../../Style/HomePageCss/Card.scss";
const Card = () => {
  return (
    <div className="CardMainContainer">
      <div className="cardRow">
        <div className="cardCoulmn">
          <img src={apply} alt="icon" className="CardIcon" />
        </div>
        <div className="cardCoulmn2">
          <span className="Applybtn">Apply</span>
          <span className="become">Become a Student Ambassdors</span>
        </div>
      </div>
      <div className="cardRow">
        <div className="cardCoulmn">
          <img src={apply} alt="icon" className="CardIcon" />
        </div>
        <div className="cardCoulmn2">
          <span className="Applybtn">Apply</span>
          <span className="become">Become a Student Ambassdors</span>
        </div>
      </div>
      <div className="cardRow">
        <div className="cardCoulmn">
          <img src={apply} alt="icon" className="CardIcon" />
        </div>
        <div className="cardCoulmn2">
          <span className="Applybtn">Apply</span>
          <span className="become">Become a Student Ambassdors</span>
        </div>
      </div>
      <div className="cardRow">
        <div className="cardCoulmn">
          <img src={apply} alt="icon" className="CardIcon" />
        </div>
        <div className="cardCoulmn2">
          <span className="Applybtn">Apply</span>
          <span className="become">Become a Student Ambassdors</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
