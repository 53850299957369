// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-card {
  margin-top: 54px;
  width: 23%;
  height: 382px;
}
.skeleton-card .big-card {
  width: 100%;
}
.skeleton-card .big-card .MuiSkeleton-root {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
.skeleton-card .mini-card {
  margin-top: 20px;
  width: 100%;
}
.skeleton-card .mini-card .MuiSkeleton-root {
  height: 20px;
  width: 100%;
  border-radius: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Profile/SkeletonCard.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,UAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;AACJ;AAAI;EACE,aAAA;EACA,WAAA;EACA,kBAAA;AAEN;AACE;EACE,gBAAA;EACA,WAAA;AACJ;AAAI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AAEN","sourcesContent":[".skeleton-card {\n  margin-top: 54px;\n  width: 23%;\n  height: 382px;\n\n  .big-card {\n    width: 100%;\n    .MuiSkeleton-root {\n      height: 200px;\n      width: 100%;\n      border-radius: 5px;\n    }\n  }\n  .mini-card {\n    margin-top: 20px;\n    width: 100%;\n    .MuiSkeleton-root {\n      height: 20px;\n      width: 100%;\n      border-radius: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
