import React from "react";
import mainImage from "../../Utils/Images/studentAmbassdors.jpeg";
import "../../Style/HomePageCss/Poetryambessdors.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
const PoetryAmbassadors = () => {
  const navigate = useNavigate();
  return (
    <div className="Poetry_Ambassdors_main">
      <img
        src={mainImage}
        alt="kavishala_Poetry"
        className="Poetry_Ambassdors_main-img"
      />

      <div className="Poetry_Ambassdors_Middle"></div>
      <div className="Poetry_Ambassdors_last">
        <div className="Poetry_Ambassdors_move">
          <div className="Poetry_Ambassdors_heading">
            <span className="PoetryAmbasdors">Kavishala</span>
            <span className="PoetryAmbasdors"> Campus Ambassadors</span>
          </div>
        </div>
      </div>
      <div className="Poetry_Ambassdors_FinalContainer">
        <div className="Poetry_Ambassdors_Final_1stDiv">
          <div className="Poetry_Ambassdors_Final_2ndDiv">
            <div className="Poetry_Ambassdors_Final_3rdDiv">
              <span className="BecomeAmbassador">
                Kavishala Campus Ambassadors
              </span>
            </div>
            <div className="Poetry_Ambassdors_Final_3rdDivw">
              <p className="BecomeAmbassadorText">
                Kavishala Campus Ambassadors are a global group of on-campus
                Leaders who are eager to help fellow students, lead their local
                literary community, and develop literary awareness and poetic
                skills. They provide a platform for aspiring individuals to gain
                knowledge.
              </p>
              {localStorage?.getItem("Ambassadors_token") ? (
                <Button
                  variant="contained"
                  className="Apply-button"
                  onClick={() => navigate("/all-Ambassadors")}
                >
                  All KCAs
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="Apply-button"
                  onClick={() => navigate("/apply")}
                >
                  Apply Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoetryAmbassadors;
