import React from "react";
import "../../Components/Profile/Profile.scss";
import profile from "../../Utils/Images/StudentConversation.jpeg";

const CommunitySearch = () => {
  return (
    <>
      <div className="communityMain">
        <div className="communityLeft">
          <h1 className="communityHead">Community Search</h1>
          <p className="communityPara">
            Use the search filters to find Student Ambassadors and events in
            your field of interest or in your local area.
          </p>
        </div>
        <div className="communityRight">
          <img src={profile} alt="profile" className="profileImage" />
        </div>
      </div>
      <div className="studentAmbassdor">
        <h1 className="studentAmbassdorHead">Find a Student Ambassador</h1>
      </div>
    </>
  );
};

export default CommunitySearch;
