import React from "react";
import SignIn from "../../Components/SignIn/SignIn";
import SEO from "../../SEO";
const SignInPage = () => {
  return (
    <div>
      <SEO
        title={`Sign In | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_url={"/"}
        og_type="article"
        og_title={`Sign In | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_description={
          "Kavishala Campus Ambassadors are a global group of on-campus Leaders who are eager to help fellow students, lead their local literary community, and develop literary awareness and poetic skills. They provide a platform for aspiring individuals to gain knowledge."
        }
        og_image="https://ambassadors.kavishala.com/images/post_og.png"
      />
      <SignIn />
    </div>
  );
};

export default SignInPage;
