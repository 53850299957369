import React, { useState } from "react";
import Logo from "../../Utils/Images/favicon.png";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "../../Style/HomePageCss/Navbar.scss";
const Navbar = () => {
  const navigate = useNavigate();
  const [appDrawer, setAppDrawer] = useState(false);
  const handleOpen = () => {
    setAppDrawer(!appDrawer);
  };
  const logOut = () => {
    localStorage.removeItem("Ambassadors_token");
    toast("Log out Succesfull", {
      closeOnClick: false,
      closeButton: false,
      type: toast.TYPE.SUCCESS,
      autoClose: 500,
      position: toast.POSITION.TOP_RIGHT,
      className: "sucesstoaster",
    });

    setTimeout(() => {
      navigate(`/`);
    }, 3000);
  };
  return (
    <div className="Navbar">
      <div className="NavbarLeft">
        <img
          src={Logo}
          alt="Kavishala Ambassdors"
          className="BrandLogo"
          onClick={() => navigate(`/`)}
        />
        <div className="brandeContainer">
          <h1 className="BrandName" onClick={() => navigate(`/`)}>
            Kavishala Campus Ambassadors
          </h1>
        </div>
      </div>
      <div className="NavbarRight">
        <div className="NavbarAmbassadors">
          {/* <h1 className="Ambassadors">Learn Kavishala Ambassadors</h1> */}
          <ul className="navbar-ul">
            <li className="navbar-li" onClick={() => navigate("/about")}>
              About
            </li>
            <li
              className="navbar-li"
              onClick={() => navigate("/all-Ambassadors")}
            >
              All KCAs
            </li>
            <li className="navbar-li" onClick={() => navigate("/summit")}>
              KCA Summit
            </li>
            <li className="navbar-li" onClick={() => navigate("/events")}>
              Events
            </li>
            <li className="navbar-li" onClick={() => navigate("/resources")}>
              Resources
            </li>
            <li className="navbar-li" onClick={() => navigate("/faq")}>
              FAQ
            </li>
            <li className="navbar-li" onClick={() => navigate("/contact-us")}>
              Contact Us
            </li>
          </ul>
        </div>
        <FontAwesomeIcon onClick={handleOpen} className="menu" icon={faBars} />
        <Drawer
          anchor={"right"}
          open={appDrawer}
          onClose={() => setAppDrawer(false)}
          className="Drawer"
        >
          <div className="DrawerMainContainer">
            <div className="TopConatiner">
              <div className="TopConatiner_Left">
                <h1 className="AmbassadorsText">Learn Kavishala Ambassadors</h1>
                <FontAwesomeIcon className="Search" icon={faMagnifyingGlass} />
              </div>
              <div className="TopConatiner_right">
                <FontAwesomeIcon
                  className="close"
                  icon={faXmark}
                  onClick={() => setAppDrawer(false)}
                />
              </div>
            </div>
            <div className="MideumContainer">
              {localStorage?.getItem("Ambassadors_token") ? (
                ""
              ) : (
                <NavLink to="/apply" style={{ textDecoration: "none" }}>
                  <p className="routerBtn">Apply</p>
                </NavLink>
              )}
              <NavLink to="/all-Ambassadors" style={{ textDecoration: "none" }}>
                <p className="routerBtn">All KCAs</p>
              </NavLink>

              <a
                href=" https://kavishala.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="routerBtn">Questions</p>
              </a>

              <p className="routerBtn">Find a Kavishala Ambassadors</p>
              <a
                href=" https://kavishala.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="routerBtn">Learn Poetry and Story telling</p>
              </a>
              <NavLink to="/about" style={{ textDecoration: "none" }}>
                <p className="routerBtn">About</p>
              </NavLink>
              <NavLink to="/summit" style={{ textDecoration: "none" }}>
                <p className="routerBtn">KCA Summit</p>
              </NavLink>
              <NavLink to="/events" style={{ textDecoration: "none" }}>
                <p className="routerBtn">Events</p>
              </NavLink>
              <NavLink to="/resources" style={{ textDecoration: "none" }}>
                <p className="routerBtn">Resources</p>
              </NavLink>
              <NavLink to="/FAQ" style={{ textDecoration: "none" }}>
                <p className="routerBtn">FAQ</p>
              </NavLink>
              <NavLink to="/contact-us" style={{ textDecoration: "none" }}>
                <p className="routerBtn">Contact Us</p>
              </NavLink>
            </div>
            {localStorage?.getItem("Ambassadors_token") ? (
              <div className="DownContaier">
                <p className="DownContainerText">Welcome</p>

                <Button
                  variant="contained"
                  className="SignIn_Button"
                  onClick={logOut}
                >
                  Log out
                  <ToastContainer />
                </Button>
              </div>
            ) : (
              <div className="DownContaier">
                <p className="DownContainerText">Welcome</p>

                <Button
                  variant="contained"
                  className="SignIn_Button"
                  onClick={() => navigate("/signIn")}
                >
                  Sign in
                </Button>
              </div>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Navbar;
