import React from "react";
import Navbar from "../../Components/DesktopPage/Navbar";
import PoetryAmbassadors from "../../Components/DesktopPage/PoetryAmbassadors";
import PoetryGlobal from "../../Components/DesktopPage/PoetryGlobal";
import Card from "../../Components/DesktopPage/Card";
import ApplyCard from "../../Components/DesktopPage/ApplyCard";
import HowWork from "../../Components/DesktopPage/HowWork";
import Tamplate from "../../Components/DesktopPage/Tamplate";
import CaroselCard from "../../Components/DesktopPage/CaroselCard";
import Template2 from "../../Components/DesktopPage/Template2";
import ResourceCard from "../../Components/DesktopPage/ResourceCard";
import Footer from "../../Components/DesktopPage/Footer";
const Desktop = () => {
  return (
    <div>
      <Navbar />
      <PoetryAmbassadors />
      <PoetryGlobal />
      <Card />
      <ApplyCard />
      <HowWork />
      <Tamplate />
      <CaroselCard />
      <Template2 />
      <ResourceCard />
      <Footer />
    </div>
  );
};

export default Desktop;
