import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../Components/SignIn/Sign.scss";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Logo from "../../Utils/Images/Kavishala Logo.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
const SignUpStep2 = ({ saveData, saveData2 }) => {
  const [data, setData] = useState({});

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    saveData(data);
  };
  const handleTrial = () => {
    saveData2(data);
  };
  return (
    <div className="signBox">
      <div className="SignInContainer">
        <div className="Logo">
          <img src={Logo} alt="Kavishala Ambassdors" className="BrandLogo" />
          <h1 className="BrandName">Kavishala Campus Ambassadors</h1>
        </div>
        <div className="SignIn">
          <h1 className="SignInHead">Sign up</h1>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <TextField
            label="Instagram Handle"
            type="text"
            variant="standard"
            className="inputText"
            placeholder="Your answer"
            name="instagram_url"
            onChange={handleInput}
          />
          <TextField
            label="Facebook Handle"
            type="text"
            variant="standard"
            className="inputText"
            placeholder="Your answer"
            name="facebook_url"
            onChange={handleInput}
          />
          <TextField
            label="Twitter Handle"
            type="text"
            variant="standard"
            className="inputText"
            placeholder="Your answer"
            name="twitter_url"
            onChange={handleInput}
          />
          <TextField
            label="Bio"
            type="text"
            variant="standard"
            className="inputText"
            placeholder="Your answer"
            name="bio"
            onChange={handleInput}
          />
          <div className="btnContainer">
            <Button
              variant="contained"
              className="LoginBtn"
              onClick={handleTrial}
              type="submit"
            >
              Back
            </Button>
            <Button variant="contained" className="LoginBtn" type="submit">
              sign up
              <ToastContainer />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUpStep2;
