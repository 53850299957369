// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whyShould {
  width: 85%;
  margin: 2.5% 7.5%;
  font-size: 20px;
  font-weight: 600;
  color: #00102c;
}

.ResourceCardContainer {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 0 7.5%;
}
.ResourceCardContainer .Cardmain {
  width: 24%;
}
.ResourceCardContainer .Cardmain .cardMedia {
  height: 100% !important;
}
.ResourceCardContainer .Cardmain .cardContent {
  padding: 10%;
}
.ResourceCardContainer .Cardmain .cardContent .text {
  font-size: 20px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  display: inline;
  line-height: 28px;
}
.ResourceCardContainer .Cardmain .cardContent .content {
  font-size: 16px;
  font-weight: 400;
  color: rgb(59, 58, 57);
  display: inline;
  line-height: 22px;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Style/HomePageCss/Resource.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACF;;AACA;EACE,UAAA;EACA,aAAA;EACA,8BAAA;EACA,cAAA;AAEF;AADE;EAEE,UAAA;AAEJ;AADI;EACE,uBAAA;AAGN;AADI;EACE,YAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;AAIR;AAFM;EACE,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;AAIR","sourcesContent":[".whyShould {\n  width: 85%;\n  margin: 2.5% 7.5%;\n  font-size: 20px;\n  font-weight: 600;\n  color: #00102c;\n}\n.ResourceCardContainer {\n  width: 85%;\n  display: flex;\n  justify-content: space-between;\n  margin: 0 7.5%;\n  .Cardmain {\n    // border-radius: 0 !important;\n    width: 24%;\n    .cardMedia {\n      height: 100% !important;\n    }\n    .cardContent {\n      padding: 10%;\n      .text {\n        font-size: 20px;\n        font-weight: 600;\n        color: rgb(50, 49, 48);\n        display: inline;\n        line-height: 28px;\n      }\n      .content {\n        font-size: 16px;\n        font-weight: 400;\n        color: rgb(59, 58, 57);\n        display: inline;\n        line-height: 22px;\n        margin: 0px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
