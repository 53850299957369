import React, { useState, useEffect } from "react";

import "../../Components/Profile/Profile.scss";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "../../Style/HomePageCss/CarouselCard.scss";
import Profile from "../../Utils/Images/StudentConversation.jpeg";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { allUser, searchItem } from "../../Api/Api";
const ProfileData = ({ paginat, data }) => {
  const navigate = useNavigate();

  return (
    <div className="searchProfileCard">
      {data?.slice(paginat.start, paginat?.end)?.map((value) => {
        console.log("value", value);
        return (
          <div
            className="CardContainer"
            onClick={() =>
              navigate(`/profile/${value?.username}`, {
                state: value,
              })
            }
          >
            <Card sx={{ maxWidth: 345 }} className="CardMain">
              <div className="Header">
                <div className="headerTitleContainer">
                  <h1 className="Title">Learn Student Ambassador</h1>
                </div>
              </div>

              <div className="cardMedia">
                <CardMedia
                  component="img"
                  height="194"
                  image={value?.image === null ? Profile : value?.image}
                  alt="Paella dish"
                  className="profileImage"
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="content"
                >
                  {value?.name}
                </Typography>
              </div>
              <CardContent className="ContentMain">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="content"
                >
                  {value?.bio}
                </Typography>
              </CardContent>
              <div className="line"></div>
              <div className="footerTitleContainer">
                <h1 className="Title">
                  {value?.city === "undefined" ? "" : value?.city}{" "}
                  <span>India</span>
                </h1>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default ProfileData;
