import React, { useState } from "react";
import SignUp from "../../Components/SignUp/SignUp";
import SignUpStep2 from "../../Components/SignUp/SignUpStep2";
import { AuthRegister } from "../../Api/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SEO from "../../SEO";
const SignUppage = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleApi = (data) => {
    const formData = new FormData();
    formData.append("image", data?.image);
    console.log("data", formData.getAll("image")[0]);
    if (formData.getAll("image")[0] === "undefined") {
      formData.delete("image");
    }

    formData.append("name", data?.name);
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("contact_number", data.contact_number);
    formData.append("college_name", data.college_name);
    formData.append("city", data.city);
    formData.append("course_year", data.course_year);
    formData.append("instagram_url", data.instagram_url);
    formData.append("facebook_url", data.facebook_url);
    formData.append("twitter_url", data.twitter_url);
    formData.append("bio", data.bio);
    formData.append("password", data.password);
    console.log("jhgfghj", formData.getAll("image")[0] === undefined);
    AuthRegister(formData)
      .then((res) => {
        console.log("jhgfghjk", res);
        localStorage.setItem("Ambassadors_token", res?.data?.token);
        if (res.status === 200) {
          toast("Sign up Succesfull", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            navigate(`/`);
          }, 3000);
        }
      })
      .catch((e) =>
        e?.response?.status === 400
          ? toast(e?.response?.data?.msg, {
              closeOnClick: false,
              closeButton: false,
              type: toast.TYPE.ERROR,
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              draggable: true,
              rtl: false,
              pauseOnFocusLoss: false,
              className: "sucesstoaster",
            })
          : ""
      );

    setData(data);
  };

  return (
    <div>
      <SEO
        title={`Apply | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_url={"/apply"}
        og_type="article"
        og_title={`Apply | Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_description={
          "Kavishala Campus Ambassadors are a global group of on-campus Leaders who are eager to help fellow students, lead their local literary community, and develop literary awareness and poetic skills. They provide a platform for aspiring individuals to gain knowledge."
        }
        og_image="https://ambassadors.kavishala.com/images/post_og.png"
      />
      {step === 0 && (
        <SignUp
          saveData={(e) => {
            setData({ ...data, ...e });
            setStep(1);
          }}
          privious_data={data}
        />
      )}
      {step === 1 && (
        <SignUpStep2
          saveData={(e) => handleApi({ ...data, ...e })}
          saveData2={(e) => {
            setData(data);
            setStep(0);
          }}
        />
      )}
    </div>
  );
};

export default SignUppage;
