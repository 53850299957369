import axios from "axios";
// const baseUrl = "https://ambassadorsadmin.kavishala.in";
const baseUrl = "https://admin.kavishala.in";
const AuthLogin = (loginData) => {
  const link = "/ambassadors/login/";
  const url = `${baseUrl}${link}`;

  try {
    const response = axios.post(url, loginData).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AuthRegister = (data) => {
  const link = "/ambassadors/register/";
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Blogs = () => {
  const link = "/ambassadors/blog/";
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allUser = () => {
  const link = "/alluser/";
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const searchItem = (data) => {
  if (data === undefined) {
    data = "";
  }
  const link = `/ambassadors/search/?search=${data}`;
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const staicPageDetails = (slug) => {
  const link = `/ambassadors/static_page/${slug}`;
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getTask = () => {
  const link = `/ambassadors/task_list/`;
  const url = `${baseUrl}${link}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("Ambassadors_token")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const postCommentTask = (data) => {
  const link = `/ambassadors/submit_answer/`;
  const url = `${baseUrl}${link}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  AuthLogin,
  AuthRegister,
  Blogs,
  allUser,
  searchItem,
  staicPageDetails,
  getTask,
  postCommentTask,
};
