import React from "react";
import Level from "../../Utils/Images/Level.png";
import Button from "@mui/material/Button";
import "../../Style/HomePageCss/Template.scss";
const Tamplate = () => {
  return (
    <div className="templateConatainer">
      <div className="first">
        <h2 className="templateConatainerHead">
          Learn Student Ambassador Milestones
        </h2>
        <span className="templateConatainerContent">
          Student Ambassadors complete activities and make contributions to the
          community in order to progress through milestones and unlock
          additional benefits.
        </span>
        <Button variant="contained" className="Apply-button">
          Learn More
        </Button>
      </div>
      <div className="second">
        <img className="templateConatainerImg" src={Level} alt="Level" />
        <img className="templateConatainerImg" src={Level} alt="Level" />
        <img className="templateConatainerImg" src={Level} alt="Level" />
      </div>
    </div>
  );
};

export default Tamplate;
