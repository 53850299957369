import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../Style/HomePageCss/Footer.scss";
const XIcon = () => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="icon"
  >
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </svg>
);
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="FooterMain">
      <div className="FotterFirstDiv">
        <div className="IconContainer">
          <FontAwesomeIcon
            icon={faInstagram}
            className="icon"
            onClick={() =>
              window.open("https://www.instagram.com/kca_kavishala/")
            }
          />
        </div>
        <div className="IconContainer">
          <FontAwesomeIcon
            icon={faFacebookF}
            className="icon"
            onClick={() =>
              window.open("https://www.facebook.com/kavishala.ambassadors/")
            }
          />
        </div>
        <div
          className="IconContainer"
          onClick={() => window.open("https://x.com/k_ambassadors")}
        >
          <XIcon />
        </div>
        <div className="IconContainer">
          <FontAwesomeIcon icon={faYoutube} className="icon" />
        </div>
        <div
          className="IconContainer"
          onClick={() =>
            window.open(" https://in.linkedin.com/company/kavishala")
          }
        >
          <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
        </div>
      </div>

      <div className="FotterThirdDiv">
        {/* <div className="Language"></div> */}
        <div className="staticLink">
          <span
            className="staicLinkContent"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </span>
          <span
            className="staicLinkContent"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Terms & Conditions
          </span>

          <span className="staicLinkContent">© Kavishala Ambassadors 2024</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
