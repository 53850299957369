import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../Components/SignIn/Sign.scss";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../Utils/Images/favicon.png";
import { useNavigate } from "react-router-dom";
import { AuthLogin } from "../../Api/Api";

const SignIn = () => {
  const [data, setData] = useState();

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    AuthLogin(data)
      .then((res) => {
        const response = res;
        localStorage.setItem("Ambassadors_token", response?.data?.token);

        toast("Sign in Succesfull", {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
          className: "sucesstoaster",
        });

        setTimeout(() => {
          navigate(`/`);
        }, 3000);
      })
      .catch((e) =>
        e?.response?.status === 400
          ? toast(e?.response?.data?.msg, {
              closeOnClick: false,
              closeButton: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              draggable: true,
              rtl: false,
              pauseOnFocusLoss: false,
              className: "sucesstoaster",
            })
          : ""
      );
  };
  return (
    <>
      <div className="signBox">
        <div className="SignInContainer">
          <div className="Logo" onClick={() => navigate(`/`)}>
            <img src={Logo} alt="Kavishala Ambassdors" className="BrandLogo" />
            <h1 className="BrandName">Kavishala Campus Ambassadors</h1>
          </div>
          <div className="SignIn">
            <h1 className="SignInHead">Sign in</h1>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              id="email"
              label="email / username"
              // type="email"
              variant="standard"
              className="inputText"
              onChange={handleChange}
              name="email"
              required
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="standard"
              className="password"
              name="password"
              onChange={handleChange}
              required
            />
            <p className="NoAccounts">
              No account?{" "}
              <span
                className="createAccount"
                onClick={() => navigate(`/apply`)}
              >
                Create one!
              </span>
            </p>
            <div className="btnContainer">
              <Button variant="contained" className="LoginBtn" type="submit">
                Log in
                <ToastContainer />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default SignIn;
