import React, { useState, useEffect } from "react";
import "../Tasks/task.css";
import { ToastContainer, toast } from "react-toastify";
import { getTask, postCommentTask } from "../../Api/Api";
import { useNavigate } from "react-router-dom";
const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const loadTasks = async () => {
      await getTask()
        .then((res) => {
          // const res = await getTask();
          console.log("hgfghj", res);
          const tasksData = res.data.map((task) => ({
            ...task,
            checked: false,
            comment: "",
          }));
          setTasks(tasksData);
        })
        .catch((e) => {
          if (e.response.status) {
            navigate(`/signIn`);
          }
        });
    };
    loadTasks();
  }, [isLoaded]);

  const handleCheckboxChange = (taskId) => {
    setTasks(
      tasks.map((task) =>
        task.id === taskId ? { ...task, checked: !task.checked } : task
      )
    );
  };

  const handleCommentChange = (taskId, event) => {
    setTasks(
      tasks.map((task) =>
        task.id === taskId ? { ...task, comment: event.target.value } : task
      )
    );
  };

  const handleCommentSubmit = (taskId) => {
    const task = tasks.find((task) => task.id === taskId);

    setTasks(
      tasks.map((task) =>
        task.id === taskId ? { ...task, comment: "" } : task
      )
    );
    const data = {
      task: task.id,
      comment: task.comment,
      is_Completed: true,
      token: localStorage.getItem("Ambassadors_token"),
    };
    postCommentTask(data)
      .then((res) => {
        console.log("res", res);
        if (res.status === 201) {
          toast("Your Answer Submitted", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });
          setTimeout(() => {
            setIsLoaded(!isLoaded);
          }, 1000);
        }
      })
      .catch((e) => {
        toast(e?.response?.data?.msg, {
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          draggable: true,
          rtl: false,
          pauseOnFocusLoss: false,
          className: "sucesstoaster",
        });
      });
    console.log("task", task);
  };

  console.log("lkjhgfghj", tasks);
  return (
    <div className="task-list">
      {tasks.map((task) => (
        <div className="task" key={task.id}>
          {task.task_status ? (
            <input
              type="checkbox"
              id={`task-${task.id}`}
              className="custom-checkbox"
              checked={true}
            />
          ) : (
            <input
              type="checkbox"
              id={`task-${task.id}`}
              className="custom-checkbox"
              checked={task.checked}
              onChange={() => handleCheckboxChange(task.id)}
            />
          )}

          <label
          // htmlFor={`task-${task.id}`}
          >
            <h4 className="task-heading">{task.title}</h4>
            <p
              className="task-comments"
              dangerouslySetInnerHTML={{
                __html: task.description,
              }}
            />
            {task.checked && (
              <div className="comment-section">
                <textarea
                  placeholder="Add your comment here..."
                  value={task.comment}
                  onChange={(event) => handleCommentChange(task.id, event)}
                />
                <button
                  className="comment-section-btn"
                  onClick={() => handleCommentSubmit(task.id)}
                >
                  Submit Comment
                  <ToastContainer />
                </button>
              </div>
            )}
          </label>
        </div>
      ))}
    </div>
  );
};

export default TaskList;
