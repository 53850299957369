import React from "react";
import mainImage from "../../Utils/Images/StudentConversation.jpeg";
import Button from "@mui/material/Button";
import "../../Style/HomePageCss/Tempcard2.scss";
const Template2 = () => {
  return (
    <div className="template2Conatainer">
      <div className="first">
        <h2 className="templateConatainerHead">Empower you even more</h2>
        <span className="templateConatainerContent">
          As a Student Ambassador, join the Windows Insider Program to preview
          Windows and enjoy exclusive benefits, like session support from the
          Windows team.
        </span>
        <Button variant="contained" className="Apply-button">
          Find out how!
        </Button>
      </div>
      <div className="second">
        <img
          className="templateConatainerImg"
          src={mainImage}
          alt="mainImage"
        />
      </div>
    </div>
  );
};

export default Template2;
