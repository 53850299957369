import React from "react";
import mainImage from "../../Utils/Images/StudentHome.jpg";
import "../../Style/HomePageCss/Poetry.scss";
const PoetryGlobal = () => {
  return (
    <>
      <div className="poetryContainer">
        <div className="header">
          <img
            src={mainImage}
            alt="kavishala_Poetry"
            className="Poetry_Ambassdors_main-img"
          />
          <div className="poetryContainer2">
            <h1 className="poetryContainer_Head">
              Be a force for good-locally and globally
            </h1>
            <p className="poetryContainer_paragraph">
              You might find yourself helping fellow students build their coding
              skills online with Kavishala Ambassadors Learn, organizing a
              virtual hackathon to solve real-world challenges, earning
              certifications, or building digital communities—it’s all up to
              you.
            </p>
          </div>

          <div className="poetryContainerBox"> </div>
        </div>
      </div>
    </>
  );
};

export default PoetryGlobal;
