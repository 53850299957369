import React from "react";
import mainImage from "../../Utils/Images/StudentHome.jpg";
import Button from "@mui/material/Button";
import "../../Style/HomePageCss/HowWorks.scss";
const HowWork = () => {
  return (
    <>
      <div className="workContainer">
        <div className="header">
          <img
            src={mainImage}
            alt="kavishala_Poetry"
            className="Poetry_Ambassdors_main-img"
          />
          <div className="poetryContainer2">
            <h1 className="poetryContainer_Head">How it works</h1>
            <p className="poetryContainer_paragraph">
              The Student Ambassadors program provides plenty of opportunities
              for you to learn and lead so you can make a difference and empower
              those around you.
            </p>
            <Button variant="contained" className="Apply-button">
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWork;
