import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../Components/SignUp/Signup.scss";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Logo from "../../Utils/Images/Kavishala Logo.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Dp from "../../Utils/Images/dp.jpg";
const SignUp = ({ saveData, privious_data }) => {
  console.log("fghjkjhgsx", privious_data);
  const [data, setData] = useState(privious_data);
  const [errors, setError] = useState(false);
  const [img, setImg] = useState();
  const [imageURLS, setImageURLs] = useState();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  localStorage.setItem("Dp", data?.image);

  const handleImage = (e) => {
    const image = e.target.files[0];
    setImg(image);
    setData({ ...data, image });
    console.log("gftghjhu", image);
    setImageURLs(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data?.password !== data?.confirm_password) {
      setError(true);
    } else {
      setError(false);
      saveData(data);
    }
  };
  const profile_pic = localStorage.getItem("Dp");
  console.log("lkjhgvbnm", data);
  return (
    <div className="signupBox">
      <div className="SignInContainer">
        <div className="Logo" onClick={() => navigate(`/`)}>
          <img src={Logo} alt="Kavishala Ambassdors" className="BrandLogo" />
          <h1 className="BrandName">Kavishala Campus Ambassadors</h1>
        </div>
        <div className="SignIn">
          <h1 className="SignInHead">Sign up</h1>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="ProfilePhoto">
            <img
              src={data?.image === undefined ? Dp : imageURLS}
              alt=""
              style={{
                borderRadius: "50%",
                border: "1px solid #AF4A37",
                width: "100px",
                height: "100px",
              }}
            />
            <TextField
              id="Profile_file-input"
              label="Your Name"
              type="file"
              variant="standard"
              className="password"
              name="image"
              onChange={handleImage}
              style={{ display: "none" }}
            />
            <label for="Profile_file-input">
              <FontAwesomeIcon
                // className="cameraDpIcon"
                icon={faCamera}
                style={{
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
          <TextField
            id="name"
            label="Your Name"
            type="text"
            variant="standard"
            className="password"
            name="name"
            value={data?.name}
            onChange={handleChange}
            required
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            variant="standard"
            className="inputText"
            name="email"
            value={data?.email}
            onChange={handleChange}
            required
          />
          <TextField
            id="userName"
            label="User Name"
            type="text"
            variant="standard"
            className="inputText"
            name="username"
            value={data?.username}
            onChange={handleChange}
            inputProps={{ pattern: "[a-zA-Z0-9_]+" }}
            // onInvalid={(F) =>
            //   F.target.setCustomValidity(
            //     "Your username can only contain letters, numbers and '_'"
            //   )
            // }
            required
          />
          <TextField
            id="contact_number"
            label="Contact Number"
            type="number"
            variant="standard"
            className="password"
            name="contact_number"
            value={data?.contact_number}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            required
          />
          <TextField
            id="College_Name"
            label="College Name"
            type="text"
            variant="standard"
            className="password"
            name="college_name"
            value={data?.college_name}
            onChange={handleChange}
          />
          <TextField
            id="City"
            label="City"
            type="text"
            variant="standard"
            className="password"
            value={data?.city}
            onChange={handleChange}
            name="city"
          />
          <TextField
            id="Course"
            label="Course and Year"
            type="text"
            variant="standard"
            className="password"
            value={data?.course_year}
            onChange={handleChange}
            name="course_year"
          />
          <TextField
            id="Password"
            label="Password"
            type="password"
            variant="standard"
            className="password"
            name="password"
            value={data?.password}
            onChange={handleChange}
            required
            inputProps={{ minLength: 8 }}
          />
          <TextField
            id="Confirm_Password"
            label="Confirm Password"
            type="password"
            variant="standard"
            className="password"
            name="confirm_password"
            value={data?.confirm_password}
            onChange={handleChange}
            helperText={errors === true ? "Password does not match" : ""}
            required
          />
          <p className="NoAccounts">
            Already account?{" "}
            <span className="createAccount" onClick={() => navigate(`/signin`)}>
              Sign in!
            </span>
          </p>
          <div className="btnContainer">
            <Button variant="contained" className="LoginBtn" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUp;
