import React from "react";
import "../../Style/HomePageCss/Resource.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import studentConversation from "../../Utils/Images/StudentConversation.jpeg";
const ResourceCard = () => {
  return (
    <>
      <h1 className="whyShould">Student resources</h1>
      <div className="ResourceCardContainer">
        <Card className="Cardmain">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={studentConversation}
              alt="green iguana"
              className="cardMedia"
            />
            <CardContent className="cardContent">
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Open now
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="Cardmain">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={studentConversation}
              alt="green iguana"
              className="cardMedia"
            />
            <CardContent className="cardContent">
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Open now
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="Cardmain">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={studentConversation}
              alt="green iguana"
              className="cardMedia"
            />
            <CardContent className="cardContent">
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Open now
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="Cardmain">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={studentConversation}
              alt="green iguana"
              className="cardMedia"
            />
            <CardContent className="cardContent">
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Open now
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
};

export default ResourceCard;
