import React from "react";
import Navbar from "../../Components/DesktopPage/Navbar";
import PoetryAmbassadors from "../../Components/DesktopPage/PoetryAmbassadors";
import ApplyCard from "../../Components/DesktopPage/ApplyCard";
import Footer from "../../Components/DesktopPage/Footer";
import SEO from "../../SEO";
const Applypage = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_url={"/"}
        og_type="article"
        og_title={`Kavishala Campus Ambassadors | Literature and Poetry in Your College Campus`}
        og_description={
          "Kavishala Campus Ambassadors are a global group of on-campus Leaders who are eager to help fellow students, lead their local literary community, and develop literary awareness and poetic skills. They provide a platform for aspiring individuals to gain knowledge."
        }
        og_image="https://ambassadors.kavishala.com/images/post_og.png"
      />
      <Navbar />
      <PoetryAmbassadors />

      <ApplyCard />
      <Footer />
    </div>
  );
};

export default Applypage;
