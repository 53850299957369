import React from "react";
import "../../Components/ProfileDetails/ProfileDetails.scss";
import Profile from "../../Utils/Images/StudentConversation.jpeg";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
const ProfileDetails = ({ state }) => {
  return (
    <>
      <div className="ProfileDetails">
        <div className="ProfileContainer">
          <div className="profileLeft">
            <img
              src={state?.image === null ? Profile : state?.image}
              alt="Profile"
              className="profile"
            />
          </div>
          <div className="ProfileRight">
            <h1>{state?.name}</h1>
            <div className="persnolDetails">
              <span>
                <LocationCityIcon style={{ color: "#8478D4" }} />
              </span>
              <span className="location">
                {state?.city === "undefined" ? "" : state.city}
              </span>
            </div>
            <div className="persnolDetails">
              <span>
                <SchoolIcon style={{ color: "#8478D4" }} />
              </span>
              <span className="location">
                {state?.college_name === "undefined" ? "" : state?.college_name}
              </span>
            </div>
            <div className="persnolDetails">
              <span>
                <EmojiEventsIcon style={{ color: "#8478D4" }} />
              </span>
              <span className="location">
                Joined{" "}
                {state?.course_year === "undefined" ? "" : state?.course_year}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileDetails">
        <div className="ProfileContainer">
          <div className="profileLeft">
            <div className="ProfileContent">
              <h1 className="ProfileHead">
                About{" "}
                <span
                  style={{
                    color: "black",
                  }}
                >
                  {state?.name}
                </span>
              </h1>
            </div>
            {/* <div className="ProfileContent">
              <h4 className="ProfileHead2">Headline</h4>
              <span className="ProfileDescriptions">{state?.bio}</span>
            </div> */}
            <div className="ProfileContent">
              <h4 className="ProfileHead">Biography</h4>
              <span className="ProfileDescriptions">
                {state?.bio === "undefined" ? "" : state?.bio}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
