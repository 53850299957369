import React, { useState, useEffect } from "react";
// import SearchBar from "material-ui-search-bar";
import SearchBar from "@mkyy/mui-search-bar";
import "../../Components/Profile/Profile.scss";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "../../Style/HomePageCss/CarouselCard.scss";
import Profile from "../../Utils/Images/StudentConversation.jpeg";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { allUser, searchItem } from "../../Api/Api";
import ProfileData from "./ProfileData";
import SkeletonCard from "./SkeletonCard";
const SearchProfile = () => {
  const navigate = useNavigate();
  const [showPerPage, setShowPerPage] = useState(6);
  const [numberOfButtuns, setNumberOfButtuns] = useState(1);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const [page, setPage] = React.useState(1);
  const [data, setData] = useState([]);
  const [inp, setInp] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = () => {
      searchItem().then((res) => {
        console.log("response", res);
        setData(res?.data);
        if (res.status === 200) {
          setLoading(true);
        }
      });
    };
    loadData();
  }, []);

  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  useEffect(() => {
    const value = showPerPage * page;
    PaginationHandle(value - showPerPage, value);
    setNumberOfButtuns(Math.ceil(data?.length / showPerPage));
  }, [page, showPerPage, data?.length]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleSearch = () => {
    searchItem(inp).then((res) => {
      setData(res?.data);
    });
  };

  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  return (
    <>
      <div className="searchContainer">
        <SearchBar
          className="searchbar"
          placeholder="Find a Kavishala Ambassadors"
          style={{ border: "2px solid #00102c" }}
          name="search"
          onChange={(newValue) => setInp(newValue)}
          onSearch={handleSearch}
        />
        <Button
          variant="contained"
          className="LoginBtn"
          type="submit"
          startIcon={<SearchIcon />}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
      {loading === false ? (
        <div className="searchProfileCard">
          <Loader />
        </div>
      ) : (
        <ProfileData paginat={paginat} data={data} />
      )}

      <div className="pagination">
        <Pagination
          count={numberOfButtuns}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        />
      </div>
    </>
  );
};

export default SearchProfile;
